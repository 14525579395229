import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const Tequila = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'Tequila | HB Privileged',
      title: 'SEA, SUN, LAND TEQUILA & HIKING TOUR',
      subtitle: 'TOUR OVERVIEW',
      text: "Este tour exclusivo de Buenaventura es la mejor forma de conocer Puerto Vallarta. Una van de último modelo te recogerá en el lobby del hotel para llevarte al centro. Aprenderás la historia de nuestro pueblo, verás nuestro famoso Malecón, la iglesia de Guadalupe, la fábrica de vidrio soplado y una mina de plata! Luego, comienza la aventura adentrándote en la jungla rumbo a una fábrica donde te mostrarán todo el proceso de elaboración de la bebida mexicana más conocida en el mundo: el tequila! Obtén una muestra gratis de más de 10 sabores diferentes! Al final del tour, sumérgete en el río o tómate una margarita en un restaurante enclavado en la selva virgen de la Sierra Madre! Un guía turístico bilingüe hará esta experiencia inolvidable. No te lo pierdas!",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'Tequila | HB Privileged',
      title: 'TOUR MAR, SOL, TIERRA TEQUILA & HIKING',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'This Buenaventura exclusive tour is the best way to get to know Puerto Vallarta. A new model van will pick you up in our lobby to take you downtown. You will learn about the history of our town, see the famous Malecon, the Guadalupe church, a blown glass factory and a silver mine! Then start the adventure by going through the jungle to a factory where they will show you the process of making the most known mexican drink in the world: tequila! Get a free taste of more than 10 different flavors of it! At the end of the tour take a dive in the river or have a margarita at a restaurant enclaved in the virgin jungle of the Sierra Madre. A bilingual tour guide will make this experience unforgettable. Don´t miss out!',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/tequila-1.png`,
    `${BASE_URL}/tours/tequila-2.jpg`,
    `${BASE_URL}/tours/tequila-3.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/tours/tequila.png`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default Tequila;